<template>
  <div id="container">
    <div style="height:60px">
    <div class="header">
       <div class="logo">
        <!-- <img src="../../assets/images/title.png" alt=""> -->
        <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
      </div>
    </div>
    </div>
      <div class="notlogin" v-if="loginStatus != true">
      <div class="text">
          登录51风流,体验快意人生
      </div>
      <div class="login_btn">
          <van-button type="primary" color="#ec4899" style="height:40px;width:70px" @click="login">登录</van-button>
          <van-button type="primary" color="#ec4899" style="height:40px;width:70px" @click="register">注册</van-button>
      </div>
    </div>
    <!-- vip登录成功 -->
    <div class="success" v-if="userInfo.usertype>1">
      <li style="font-size:20px;font-weight:700">尊敬的Vip,欢迎登录</li>
      <li v-if="userInfo.usertype==3" style="font-size:16px"><van-icon name="contact" color="#ec4899" size="18" />&nbsp;{{userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="medal-o" size="20"  />&nbsp;<span style="color:#ec4899">永久会员</span></li>
      <li v-if="userInfo.usertype==4" style="font-size:16px"><van-icon name="contact" color="#ec4899" size="18" />&nbsp;{{userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="medal-o" size="20"  />&nbsp;<span style="color:#ec4899">超级会员</span></li>
      <li v-if="userInfo.usertype==5" style="font-size:16px"><van-icon name="contact" color="#ec4899" size="18" />&nbsp;{{userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="medal-o" size="20"  />&nbsp;<span style="color:#ec4899">至尊会员</span></li>
      <li v-if="userInfo.usertype>5" style="font-size:16px"><van-icon name="contact" color="#ec4899" size="18" />&nbsp;{{userInfo.username}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="medal-o" size="20"  />&nbsp;<span style="color:#ec4899">超级管理员</span></li>
     
      <!-- <li style="font-size:16px"><van-icon
                  size="16"
                  style="margin-right: 5px"
                  name="clock-o"
                  color="#ec4899"
                /><span style="font-size: 16px; margin-right: 5px">
                  {{userInfo.countdown}}</span></li> -->
    </div>
    <!-- 用户登录 -->
    <div class="success" v-if="userInfo.usertype<2">
      <li style="font-size:20px;font-weight:700">欢迎登录</li>
      <li style="font-size:16px"><van-icon name="contact" color="#ec4899" size="18" />&nbsp;{{userInfo.username}} &nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="medal-o" size="20"  />普通用户</li>
      <li style="font-size:14px;color:#ec4899">立即升级,全站会员</li>
    </div>
    <!-- 积分佣金 -->
    <div class="role" v-if="loginStatus != false">
        <li>
          <span>积分</span>
          <i>{{userInfo.score}}</i>
        </li>
        <li>
          <span>信息</span>
          <i>1</i>
        </li>
        <li>
          <span>佣金</span>
          <i>{{userInfo.salary}}</i>
        </li>
    </div>
    <div class="listInfo">
      <ul>
     
        <!-- <li>
          <van-icon name="records" size="36" color="#ec4899" />
          <span>信息发布</span>
        </li> -->
         <li>
          <van-icon @click="mineTZ" v-if="loginStatus == true && zero!=false"  :badge="buyedNum"  name="certificate" size="36" color="#ec4899" />
          <van-icon @click="mineTZ" v-else name="certificate" size="36" color="#ec4899" />
          <span>我的解锁</span>
        </li>
         <li>
          <van-icon @click="replace" badge="666" name="hot-o" size="36" color="#ec4899" />
          <span>兑换活动</span>
        </li>
         <!-- <li>
          <van-icon @click="mineInfo" name="fire-o"  size="36" color="#ec4899" />
          <span>授权代理</span>
        </li> -->
          <li>
          <van-icon @click="morefun" badge="4" name="fire-o"  size="36" color="#ec4899" />
          <span>精品应用</span>
        </li>
        <li>
          <van-icon badge="New" @click="mineTg" name="cluster-o" size="36" color="#ec4899" />
          <span>推广赚钱</span>
        </li>
       
        <!-- <li>
          <van-icon @click="mineInfo" name="coupon-o"  size="36" color="#ec4899" />
          <span>我的信息</span>
        </li> -->
       
        
        <!-- <li>
          <van-icon name="like-o"  size="36" color="#ec4899" />
          <span>我的收藏</span>
        </li> -->
        <li>
          <van-icon is-link @click="sjhy" name="gem-o"  size="36" color="#ec4899" />
          <span>VIP升级</span>
        </li>
        <li>
          <van-icon is-link @click="jfcz" name="point-gift-o"  size="36" color="#ec4899" />
          <span>积分充值</span>
        </li>
        <li>
          <van-icon is-link @click="jfgz" name="guide-o"  size="36" color="#ec4899" />
          <span>积分规则</span>
        </li>
          <li>
          <van-icon @click="gohome" name="envelop-o"  size="36" color="#ec4899" />
          <span>回家的路</span>
        </li>
     
        
        <li>
          <van-icon is-link @click="jbzn" name="medal-o"  size="36" color="#ec4899" />
          <span>鉴别指南</span>
        </li>

        <!-- <li>
          <van-icon name="edit" @click="xgzl"  size="36" color="#ec4899" />
          <span>修改资料</span>
        </li> -->

        <li v-if="this.loginStatus">
          <!-- <van-badge  content="New"> -->
            <van-icon name="comment-o" @click="feedback"  size="36" color="#ec4899" />
          <!-- </van-badge> -->
          <span>问题反馈</span>
        </li>
      

         <li @click="resetState" v-if="userInfo.usertype==999">
          <van-icon name="apps-o"  size="36" color="#ec4899" />
          <span>后台管理</span>
        </li>
   
         <li v-if="userInfo.usertype==999 && this.loginStatus" @click="record">
          <van-icon name="notes-o"  size="36" color="#ec4899" />
          <span>登陆管理</span>
        </li>
        <li v-if="userInfo.usertype==999 && this.loginStatus" @click="logs">
          <van-icon name="notes-o"  size="36" color="#ec4899" />
          <span>系统日志</span>
        </li>
        <li v-if="this.loginStatus" @click="loginOut()">
          <van-icon name="share-o"  size="36" color="#ec4899" />
          <span>退出登录</span>
        </li>
        <li>
        </li>
        <li> 
        </li>
      </ul>
    </div>
    <!-- 积分充值 -->
      <van-popup v-model="jfczShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
    <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">积分充值</div>
    <div class="content_tip">
    <div class="content">
      <div class="font">
        <span style="font-weight:700;color:#000">第一步,查看积分类型和价格</span>
        <table align="center" border-collapse="collapse">
            <tr>
              <th align="center" valign="middle">类型</th>
              <th align="center" valign="middle">价格</th>
              <th align="center" valign="middle">支持帖子解锁</th>
              <th align="center" valign="middle">数量</th>
            </tr>
            <tr>
              <td align="center" valign="middle">体检积分</td>
              <td align="center" valign="middle">20.00</td>
              <td align="center" valign="middle">是</td>
              <td align="center" valign="middle">120+38</td>
            </tr>
            <tr>
              <td align="center" valign="middle">大众积分</td>
              <td align="center" valign="middle">58.00</td>
              <td align="center" valign="middle">是</td>
              <td align="center" valign="middle">388+58</td>
            </tr>
            <tr>
              <td align="center" valign="middle">超值积分</td>
              <td align="center" valign="middle">88.00</td>
              <td align="center" valign="middle">是</td>
              <td align="center" valign="middle">688+88</td>
            </tr>
        </table>
        <span style="line-height:20px;font-size:14px;color:#ec4899">小提示：积分可以解锁全站内容。</span><br>
        <span style="font-weight:700;color:#000;margin-top:10px">第二步,前往卡密平台购买对应的积分充值卡</span>
        <div class="font" style="font-weight: 700;">
        温馨提示：充值链接如果打不开,可以人工充值。
        </div>
        <div class="font" style="font-weight: 700;">
          客服QQ：1538373956
        </div>
        <div class="font">
        付款成功后记得返回本页面,输入升级码完成充值。
        </div>
        <!-- <div class="zfxx">微信支付</div>
        <div class="vipBtn">
          <li @click="tyjf_vx">体检积分</li>
          <li @click="dzjf_vx">大众积分</li>
          <li @click="czjf_vx">超值积分</li>
        </div>
        <div class="zfxx">支付宝支付</div> -->
        <div class="vipBtn">
          <li @click="tyjf">体检积分</li>
          <li @click="dzjf">大众积分</li>
          <li @click="czjf">超值积分</li>
          <li @click="orderQuery">卡密查询</li>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">客服邮箱：51flwhome@gmail.com,如果您在购买和使用中出现任何问题,
            请发邮件与我们联系,我们会尽快给您答复并解决您的问题。</span><br>
        <span style="font-weight:700;color:#000;margin-top:10px">第三步,在下方输入您购买的升级码完成积分充值</span>
        <div class="font">
        输入升级码,将自动为您更新积分余额。
        <div class="shengji">
          <div class="inputvip">
            <input type="text" name="" v-model="kamiJF" id="" placeholder="输入升级码">
          </div>
          <div class="sjbtn">
            <li @click="jfczbtn">立即充值</li>
          </div>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了发布信息去升级VIP，我们审核信息不会因为VIP会员而区别对待，
            只和信息本身的质量有关系。</span><br>
            <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了信息采集和共享账号去升级VIP，如果你的账号:
              使用频率异常将会被系统自动封号。</span><br>
        <div style="font-size:18px;font-weight:700">升级帮助</div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值成功了，为什么还不能看信息?</span>
        <div class="font">
        第一次充值积分后，请重新刷新页面或者退出用户名重新登录一下即可。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值中碰到问题，升级失败了怎么办?</span>
        <div class="font">
        请提供你的相关账号以及支付信息，然后发邮件给:17fengliu@gmail.com，我们会尽快给你处理。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">有事怎么联系客服?</span>
        <div class="font">
        请提供你的账号以及详细描述你的问题，然后发邮件给:待定@gmail.com，我们会尽快给你处理。
        </div>
        </div>
     </div>
    </div>
    </div>
      </van-popup>
    <!-- 我的解锁 -->
  <van-popup v-model="mineTZShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
  <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">我的解锁</div>
  <div class="content_tip">
    <div class="content" style="background-color:#f5f5f5">
        <div
          class="list"
          v-for="(item,index) in dataList"
          :key="item.id+index"
          @click="goDetail(item)"
        >
          <div :class="item.picture==''?'left1':'left'">
            <ul style="text-align: left; heigth: 100%">
              <li style="font-size: 16px; margin-bottom: 3px">
                {{ item.title }}
              </li>
              <div style="color: #a3a3a3; line-height: 30px">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(item.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ item.viewCount }}</span>
              </div>
              <div
                style="font-size:14px;width:100%;height:55px; color:#525252;
                            display:-webkit-box;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                -webkit-line-clamp:3;
                                -webkit-box-orient:vertical;"
              >
                {{ item.desc }}
              </div>
              <div
                class="bottom"
                style="height: 30px; line-height: 30px; margin-top: 18px"
              >
                <span
                  ><van-icon
                    name="guide-o"
                    size="18"
                    color="#ec4899"
                    style="margin-right: 5px" /></span
                ><span class="city" style="font-size: 14px;color:'#ec4899'">{{filterCity(item.cityCode)}}</span>
              </div>
            </ul>
          </div>
          <div class="right" v-if="item.coverPicture != '' && item.coverPicture != null">
            <!-- <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="'http://38.60.204.69/index.php?url='+baseUrl+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
            />
            <img
              v-else
              :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
            /> -->
            <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="baseUrl+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
            />
            <img
              v-else
              :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
            />
          </div>
          <div class="right" v-else>
          <div class="right" style="width:100%" v-if="item.picture != '' && item.picture != null">
            <!-- <img
              v-if="item.picture.split(',')[0].indexOf('/') != -1"
              :src="'http://38.60.204.69/index.php?url='+baseUrl+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
            />
            <img
              v-else
              :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
            /> -->
            <img
              v-if="item.picture.split(',')[0].indexOf('/') != -1"
              :src="baseUrl+item.picture.split(',')[0]"
              alt=""
              @error.once="moveErrorImg"
            />
            <img
              v-else
              :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]"
              alt=""
              @error.once="moveErrorImg"
            />
          </div>
          </div>
        </div>
    </div>
  </div>
  </van-popup>

    <!-- 会员升级 -->
      <van-popup v-model="sjhyShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
    <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">升级VIP</div>
    <div class="content_tip">
    <div class="content">
      <div class="font">
        <span style="font-weight:700;color:#000">第一步,查看会员类型和价格</span>
        <table align="center" border-collapse="collapse">
            <tr>
              <th align="center" valign="middle">类型</th>
              <th align="center" valign="middle">价格</th>
              <th align="center" valign="middle">查看内容</th>
              <th align="center" valign="middle">有效期</th>
            </tr>
            <tr>
              <td align="center" valign="middle">永久会员</td>
              <td align="center" valign="middle">88.00</td>
              <td align="center" valign="middle">51风流/一楼一凤</td>
              <!-- <td align="center" valign="middle">90天</td> -->
              <td align="center" valign="middle">永久</td>

            </tr>
            <tr>
              <td align="center" valign="middle">超级会员</td>
              <td align="center" valign="middle">158.00</td>
              <td align="center" valign="middle">51风流/一楼一凤/一品楼</td>
              <td align="center" valign="middle">永久</td>
            </tr>
            <tr>
              <td align="center" valign="middle">至尊会员</td>
              <td align="center" valign="middle">288.00</td>
              <td align="center" valign="middle">全部</td>
              <td align="center" valign="middle">永久</td>
            </tr>
        </table>
        <span style="line-height:20px;font-size:14px;color:#ec4899">小提示：升级VIP会员后有效期内无需积分浏览全站内容。</span>
        <span style="font-weight:700;color:#000;margin-top:10px">第二步,前往卡密平台购买对应的会员升级码</span><br>
        <div class="font">
        付款成功后记得返回本页面,输入升级码完成升级。
        </div>
        <span style="line-height:20px;font-size:14px;color:#ec4899">温馨提示：如果您需要使用zfb或者vx支付,请联系下方客服充值。
          客服QQ：1538373956
        </span>
        <div class="font" style="font-weight: 700;">
        温馨提示：充值链接如果打不开,可以人工充值。
        </div>
        <div class="font" style="font-weight: 700;">
          客服QQ：1538373956
        </div>
        <!-- <div class="zfxx">微信支付</div>
        <div class="vipBtn">
          <li @click="jdhy_vx">季度会员</li>
          <li @click="ndhy_vx">年度会员</li>
          <li @click="yjhy_vx">永久会员</li>
        </div>
        <div class="zfxx">支付宝支付</div> -->
        <div class="vipBtn">
          <li @click="jdhy">永久会员</li>
          <li @click="ndhy">超级会员</li>
          <li @click="yjhy">至尊会员</li>
          <li @click="orderQuery">卡密查询</li>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">客服邮箱：51flwhome@gmail.com,如果您在购买和使用中出现任何问题,
            请发邮件与我们联系,我们会尽快给您答复并解决您的问题。</span><br>
        <span style="font-weight:700;color:#000;margin-top:10px">第三步,在下方输入您购买的升级码完成会员升级</span>
        <div class="font">
        输入升级码,将自动为您更新会员等级。
        <div class="shengji">
          <div class="inputvip">
            <input type="text" name="" v-model="kami" id="" placeholder="输入升级码">
          </div>
          <div class="sjbtn">
            <li @click="hysj">立即升级</li>
          </div>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了发布信息去升级VIP，我们审核信息不会因为VIP会员而区别对待，
            只和信息本身的质量有关系。</span><br>
            <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了信息采集和共享账号去升级VIP，如果你的账号:
              使用频率异常将会被系统自动封号。</span><br>
        <div style="font-size:18px;font-weight:700">升级帮助</div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值成功了，为什么还不能看信息?</span>
        <div class="font">
        第一次充值成为VIP会员后，请重新刷新页面或者退出用户名重新登录一下即可。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值中碰到问题，升级失败了怎么办?</span>
        <div class="font">
        请提供你的相关账号以及支付信息，然后发邮件给:51flwhome@gmail.com，我们会尽快给你处理。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">有事怎么联系客服?</span>
        <div class="font">
        请提供你的账号以及详细描述你的问题，然后发邮件给:51flwhome@gmail.com，我们会尽快给你处理。
        </div>
        </div>
     </div>
    </div>
    </div>
      </van-popup>
    <!-- 积分规则 -->
  <van-popup v-model="jfgzShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
  <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">积分规则</div>
  <div class="content_tip">
    <div class="content">
      <div class="font">
        部分信息会隐藏具体地址和联系方式，需要付出积分来查看隐藏信息。帖子的积分售价根据帖子的质量由管理员来决定，
        同时你也可以通过升级VIP会员来享受无限制查看信息的权限。
      </div>
      <div class="font" style="margin-top:10px">
          <span style="font-weight:700">查看隐藏联系方式的途径:</span><br>

          1.通过帖子的售价付出相应的积分查看。<br>

          2.购买季度/包年/终身会员可以无需积分查看所有隐藏信息。
      </div>
      <div class="font" style="margin-top:10px">
        <span style="font-weight:700">获取积分途径:</span><br>

        1.发布真实小姐信息，经过审核后，管理员会根据您的信息内容质量在6种标准中评分，当其他人查看您的信息一次,所扣的积分也将奖励到您账户。<br>

        2.你可以分享专属的推广链接给您的朋友，当你的朋友通过你的链接访问本站，你将获得奖励积分。
      </div>
      <div class="font" style="margin-top:10px">
        <span style="font-weight:700">信息发布的奖励规则:</span><br>


        1.积分奖励根据帖子内容的质量由管理员来决定，分别有6 .种信息奖励标准[0积分20积分50积分80积分100积分200积分]，当其他人查看 您的信息一次，所扣的积分也将奖励到您账户。<br>

        2.你可以使用积分查看信息的隐藏联系方式，当积分达到20000，您还可以使用积分升级会员。
      </div>
    </div>
  </div>
  </van-popup>
  <!-- 鉴别指南 -->
  <van-popup v-model="jbznShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
  <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">鉴别指南</div>
  <div class="content_tip">
    <div class="content">
      <div class="font">
          <span style="font-weight:700">怎么辨别和鉴定虛假联系方式?骗子有一些显著特征呢?</span><br>
        -当你对联系对方有所顾虑的时候，请您尝试百度一下这个联系方式，进行多方的比对。<br>
        -当你对联系对方有所顾虑的时候，请您尝试百度一下这个联系方式，进行多方的比对。<br>
        -凡是要求付路费/红包/定金/保证金/照片验证/视频验证等任何提前付费的行为，基本都是骗子。<br>
        -凡是裸聊、发视频验证、下载APP的，都是来骗钱或窃取个人资料的，请勿上当。<br>
        -凡是进入服务场所后没有提供服务就一直推荐办卡都是骗子。<br>
        -凡是对，服务项目含糊其辞的，都是有时候猫腻的，事前一定要问清楚。 <br>
        -凡是要求见面地方在交通方便的车站附近的，基本不靠谱。<br>
      </div>
      <div class="font" style="margin-top:10px">
          <span style="font-weight:700">查为什么网站有一些信息过时了?</span><br>
          由于行业的特殊性，如果这条信息发布时间半年多了，过时了也正常，但请放心，本站每日大量更新全国信息，我们也建议你更多关注最新的信息。<br>
      </div>
      <div class="font" style="margin-top:10px">
        <span style="font-weight:700">为什么有些信息的联系方式无效?</span><br>
        由于行业的特殊性，楼凤小姐或者商家联系方式更换比较频繁，同时如果这条信息被很多狼友浏览和添加，也会导致微信或者QQ联系方式被暂时屏蔽或者直接被封。如果你无法搜索QQ和微信，这个时候请你尝试一下手机联系方式，如果长期没回复或者联系方式失效且无法添加，
        请关注我们同城的其它最新信息即可，我们每日更新大量全国信息，通过我们的最新信息，总会帮你找到快乐和性福。
      </div>
      <div class="font" style="margin-top:10px">
        <span style="font-weight:700">网站的信息都是真的吗?</span><br>
      本站信息都是全国各地狼友亲自经历后发布的，再由我们网站严格审核后才通过，所以可信度请放心。我们每条信息都有一半已显示出来，特别商家频道，肯定有很多你熟悉的地方，每天那么多的信息更新，我们没有你们想象的那么强大，可以捏造出这么多虚假信息，同时如果你有顾虑，可以尝试给我们贡献内容，获取积分后，你就可以使用积分购买单个信息的联系方式，
      所以，本站信息是不是真的请你判断，如果你还是认为本站信息是假的，那么请你离开本站，感谢配合
      </div>
    </div>
  </div>
  </van-popup>
  <!--  -->
  <van-popup v-model="gohomeShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
   <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">联系我们</div>
    <div class="content_tip">
      <div class="content">
        <div class="font">
            <span style="font-weight:700">如果您有任何问题请通过邮件与我们取得联系!</span><br>
            <span style="font-weight:700">最新网址请联系下方邮箱自动回复获取!</span><br>
            <span style="color:#ec4899">与你客服号：flyuni69</span><br>
            <span style="color:#ec4899">最新地址：91gpw.xyz</span><br>
            <span style="color:#ec4899">最新地址：51ym.xyz</span><br>
            <span style="color:#ec4899">更多应用：16ym.xyz</span><br>
            <span style="color:#ec4899">邮箱：51flwhome@gmail.com</span><br>
            备注：如果您是尊敬的vip会员,请截图保存此邮箱,以防失联！！！

        </div>
      </div>
      </div>
    
  </van-popup>
  <!-- 修改资料 -->
  <van-popup v-model="xgzlShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
  <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">修改资料</div>
  <div class="content_tip">
    <div class="content">
      <van-form>
  <van-field
    v-model="username"
    name="username"
    label="用户名:"
    readonly 
  />
  <van-field
    v-model="adress"
    type="adress"
    name="adress"
    label="邮箱:"
    readonly
  />
  <van-field
    v-model="password"
    type="password"
    name="password"
    label="旧密码:"
  />
  <van-field
    v-model="password1"
    type="password"
    name="password1"
    label="新密码:"
  />

  <div class="subBtn" @click="passBtn">
    确定修改
  </div>
</van-form>
    </div>
  </div>
  </van-popup>
  </div>
</template>


<script>
// import axios from 'axios'
import { Dialog } from 'vant';
import { mapState,mapMutations} from "vuex"
import moment from "moment";
import { uploginState,getKami,upUsertype,upkmState,getTzInfo,getTZ,getJFXX,payTZ,getUserBuyNum } from "../../model/user"
import { Toast } from 'vant';
import bus from "../../utils/bus"
export default {
    data() {
      return {
          buyedNum:0,
          zero:false,    
          // baseUrl: "https://r1.img7.xyz/info/picture/",
        // baseUrl: "http://38.54.111.82/info/picture/",
        // baseUrl: "http://38.54.111.182/info/picture/",
        baseUrl: "http://178.157.60.44/info/picture/",
          status:0,
          jfgzShow:false,
          jbznShow:false,
          gohomeShow:false,
          sjhyShow:false,
          xgzlShow:false,
          jfpayShow:false,
          mineTZShow:false,
          jfczShow:false,
          kami:"",
          kamiJF:"",
          usertype:"",
          username:"",
          kmType:"",
          adress:"",
          password:"",
          password1:"",
          dataList:[],
          province:[{id:1,cityCode:"110000",cityName:"北京市",city:[{id:1,cityCode:"110000",cityName:"北京市"},
      {id:2,cityCode:"110101",cityName:"东城区"},{id:3,cityCode:"110102",cityName:"西城区"},
      {id:4,cityCode:"110105",cityName:"朝阳区"},{id:5,cityCode:"110106",cityName:"丰台区"},
      {id:6,cityCode:"110107",cityName:"石景山区"},{id:7,cityCode:"110108",cityName:"海淀区"},
      {id:8,cityCode:"110109",cityName:"门头沟区"},{id:9,cityCode:"110111",cityName:"房山区"},
      {id:10,cityCode:"110112",cityName:"通州区"},{id:11,cityCode:"110113",cityName:"顺义区"},
      {id:12,cityCode:"110114",cityName:"昌平区"},{id:13,cityCode:"110115",cityName:"大兴区"},
      {id:14,cityCode:"110116",cityName:"怀柔区"},{id:15,cityCode:"110117",cityName:"平谷区"},
      {id:16,cityCode:"110118",cityName:"密云区"},{id:17,cityCode:"110119",cityName:"延庆区"},
      ]}, {id:2,cityCode:"120000",cityName:"天津市",city:[{id:1,cityCode:"120000",cityName:"天津市"},
      {id:1,cityCode:"120101",cityName:"和平区"},{id:1,cityCode:"120102",cityName:"河东区"},
      {id:1,cityCode:"120103",cityName:"河西区"},{id:1,cityCode:"120104",cityName:"南开区"},
      {id:1,cityCode:"120105",cityName:"河北区"},{id:1,cityCode:"120106",cityName:"红桥区"},
      {id:1,cityCode:"120110",cityName:"东丽区"},{id:1,cityCode:"120111",cityName:"西青区"},
      {id:1,cityCode:"120112",cityName:"津南区"},{id:1,cityCode:"120113",cityName:"北辰区"},
      {id:1,cityCode:"120114",cityName:"武清区"},{id:1,cityCode:"120115",cityName:"宝坻区"},
      {id:1,cityCode:"120116",cityName:"滨海新区"},{id:1,cityCode:"120117",cityName:"宁河区"},
      {id:1,cityCode:"120118",cityName:"静海区"},{id:1,cityCode:"120119",cityName:"蓟州区"},
      ]}, {id:3,cityCode:"130000",cityName:"河北省",city:[{id:1,cityCode:"130000",cityName:"河北省"},
      {id:2,cityCode:"130100",cityName:"石家庄市"},{id:3,cityCode:"130200",cityName:"唐山市"},
      {id:4,cityCode:"130300",cityName:"秦皇岛市"},{id:5,cityCode:"130400",cityName:"邯郸市"},
      {id:6,cityCode:"130500",cityName:"邢台市"},{id:7,cityCode:"130600",cityName:"保定市"},
      {id:8,cityCode:"130700",cityName:"张家口市"},{id:9,cityCode:"130800",cityName:"承德市"},
      {id:10,cityCode:"130900",cityName:"沧州市"},{id:11,cityCode:"131000",cityName:"廊坊市"},
      {id:12,cityCode:"131100",cityName:"衡水市"}]},
      {id:4,cityCode:"140000",cityName:"山西省",city:[{id:1,cityCode:"140000",cityName:"山西省"},
      {id:1,cityCode:"140100",cityName:"太原市"},{id:1,cityCode:"140200",cityName:"大同市"},
      {id:1,cityCode:"140300",cityName:"阳泉市"},{id:1,cityCode:"140400",cityName:"长治市"},
      {id:1,cityCode:"140500",cityName:"晋城市"},{id:1,cityCode:"140600",cityName:"朔州市"},
      {id:1,cityCode:"140700",cityName:"晋中市"},{id:1,cityCode:"140800",cityName:"运城市"},
      {id:1,cityCode:"140900",cityName:"忻州市"},{id:1,cityCode:"141000",cityName:"临汾市"},
      {id:1,cityCode:"1401100",cityName:"吕梁市"}]},
      {id:5,cityCode:"150000",cityName:"内蒙古自治区",city:[{id:1,cityCode:"150000",cityName:"内蒙古自治区"},
      {id:2,cityCode:"150100",cityName:"呼和浩特市"},{id:3,cityCode:"150200",cityName:"包头市"},
      {id:4,cityCode:"150300",cityName:"乌海市"},{id:5,cityCode:"150400",cityName:"赤峰市"},
      {id:6,cityCode:"150500",cityName:"通辽市"},{id:7,cityCode:"150600",cityName:"鄂尔多斯市"},
      {id:8,cityCode:"150700",cityName:"呼伦贝尔市"},{id:9,cityCode:"150800",cityName:"巴彦淖尔市"},
      {id:10,cityCode:"150900",cityName:"乌兰察布市"},{id:11,cityCode:"152200",cityName:"兴安盟"},
      {id:12,cityCode:"152500",cityName:"锡林郭勒盟"},{id:13,cityCode:"152900",cityName:"阿拉善盟"}]}, 
      {id:6,cityCode:"210000",cityName:"辽宁省",city:[{id:1,cityCode:"210000",cityName:"辽宁省"},
      {id:2,cityCode:"210100",cityName:"沈阳市"},{id:3,cityCode:"210200",cityName:"大连市"},
      {id:4,cityCode:"210300",cityName:"鞍山市"},{id:5,cityCode:"210400",cityName:"抚顺市"},
      {id:6,cityCode:"210500",cityName:"本溪市"},{id:7,cityCode:"210600",cityName:"丹东市"},
      {id:8,cityCode:"210700",cityName:"锦州市"},{id:9,cityCode:"210800",cityName:"营口市"},
      {id:10,cityCode:"210900",cityName:"阜新市"},{id:11,cityCode:"211000",cityName:"辽阳市"},
      {id:12,cityCode:"211100",cityName:"盘锦市"},{id:13,cityCode:"211200",cityName:"铁岭市"},
      {id:14,cityCode:"211300",cityName:"朝阳市"},{id:15,cityCode:"211400",cityName:"葫芦岛市"}]},
      {id:7,cityCode:"220000",cityName:"吉林省",city:[{id:1,cityCode:"220000",cityName:"吉林省"},
      {id:2,cityCode:"220100",cityName:"长春市"},{id:3,cityCode:"220200",cityName:"吉林市"},
      {id:4,cityCode:"220300",cityName:"四平市"},{id:5,cityCode:"220400",cityName:"辽源市"},
      {id:6,cityCode:"220500",cityName:"通化市"},{id:7,cityCode:"220600",cityName:"白山市"},
      {id:8,cityCode:"220700",cityName:"松原市"},{id:9,cityCode:"220800",cityName:"白城市"},
      {id:10,cityCode:"222400",cityName:"延边州"}]}, 
      {id:8,cityCode:"230000",cityName:"黑龙江省",city:[{id:1,cityCode:"230000",cityName:"黑龙江省"},
      {id:2,cityCode:"230100",cityName:"哈尔滨市"},{id:3,cityCode:"230200",cityName:"齐齐哈尔市"},
      {id:4,cityCode:"230300",cityName:"鸡西市"},{id:5,cityCode:"230400",cityName:"鹤岗市"},
      {id:6,cityCode:"230500",cityName:"双鸭山市"},{id:7,cityCode:"230600",cityName:"大庆市"},
      {id:8,cityCode:"230700",cityName:"伊春市"},{id:9,cityCode:"230800",cityName:"佳木斯市"},
      {id:10,cityCode:"239000",cityName:"七台河市"},{id:11,cityCode:"231000",cityName:"牡丹江市"},
      {id:12,cityCode:"231100",cityName:"黑河市"},{id:13,cityCode:"231200",cityName:"绥化市"},
      {id:14,cityCode:"232700",cityName:"大兴安岭地区"}]},
      {id:9,cityCode:"310000",cityName:"上海市",city:[{id:1,cityCode:"310000",cityName:"上海市"},
      {id:2,cityCode:"310101",cityName:"黄浦区"},{id:3,cityCode:"310104",cityName:"徐汇区"},
      {id:4,cityCode:"310105",cityName:"长宁区"},{id:5,cityCode:"310106",cityName:"静安区"},
      {id:6,cityCode:"310107",cityName:"普陀区"},{id:7,cityCode:"310109",cityName:"虹口区"},
      {id:8,cityCode:"310110",cityName:"杨浦区"},{id:9,cityCode:"310112",cityName:"闵行区"},
      {id:10,cityCode:"310113",cityName:"宝山区"},{id:11,cityCode:"310114",cityName:"嘉定区"},
      {id:12,cityCode:"310115",cityName:"浦东新区"},{id:13,cityCode:"310116",cityName:"金山区"},
      {id:14,cityCode:"310117",cityName:"松江区"},{id:15,cityCode:"310118",cityName:"青浦区"},
      {id:16,cityCode:"310120",cityName:"奉贤区"},{id:17,cityCode:"310151",cityName:"崇明县"},
      ]},
      {id:10,cityCode:"320000",cityName:"江苏省",city:[{id:1,cityCode:"320000",cityName:"江苏省"},
      {id:2,cityCode:"320100",cityName:"南京市"},{id:3,cityCode:"320200",cityName:"无锡市"},
      {id:4,cityCode:"320300",cityName:"徐州市"},{id:5,cityCode:"320400",cityName:"常州市"},
      {id:6,cityCode:"320500",cityName:"苏州市"},{id:7,cityCode:"320600",cityName:"南通市"},
      {id:8,cityCode:"320700",cityName:"连云港市"},{id:9,cityCode:"320800",cityName:"淮安市"},
      {id:10,cityCode:"320900",cityName:"盐城市"},{id:11,cityCode:"321000",cityName:"扬州市"},
      {id:12,cityCode:"321100",cityName:"镇江市"},{id:13,cityCode:"321200",cityName:"泰州市"},
      {id:14,cityCode:"321300",cityName:"宿迁市"}]},
      {id:11,cityCode:"330000",cityName:"浙江省",city:[{id:1,cityCode:"330000",cityName:"浙江省"},
      {id:2,cityCode:"330100",cityName:"杭州市"},{id:3,cityCode:"330200",cityName:"宁波市"},
      {id:4,cityCode:"330300",cityName:"温州市"},{id:5,cityCode:"330400",cityName:"嘉兴市"},
      {id:6,cityCode:"330500",cityName:"湖州市"},{id:7,cityCode:"330600",cityName:"绍兴市"},
      {id:8,cityCode:"330700",cityName:"金华市"},{id:9,cityCode:"330800",cityName:"衢州市"},
      {id:10,cityCode:"330900",cityName:"舟山市"},{id:11,cityCode:"331000",cityName:"台州市"},
      {id:12,cityCode:"331100",cityName:"丽水市"}]},
      {id:12,cityCode:"340000",cityName:"安徽省",city:[{id:1,cityCode:"340000",cityName:"安徽省"},
      {id:2,cityCode:"340100",cityName:"合肥市"},{id:3,cityCode:"340200",cityName:"芜湖市"},
      {id:4,cityCode:"340300",cityName:"蚌埠市"},{id:5,cityCode:"340400",cityName:"淮南市"},
      {id:6,cityCode:"340500",cityName:"马鞍山市"},{id:7,cityCode:"340600",cityName:"淮北市"},
      {id:8,cityCode:"340700",cityName:"铜陵市"},{id:9,cityCode:"340800",cityName:"安庆市"},
      {id:10,cityCode:"341000",cityName:"黄山市"},{id:11,cityCode:"341100",cityName:"滁州市"},
      {id:12,cityCode:"341200",cityName:"阜阳市"},{id:13,cityCode:"341300",cityName:"宿州市"},
      {id:14,cityCode:"341500",cityName:"六安市"},{id:15,cityCode:"341600",cityName:"亳州市"},
      {id:16,cityCode:"341700",cityName:"池州市"},{id:17,cityCode:"341800",cityName:"宣城市"}]},
      {id:13,cityCode:"350000",cityName:"福建省",city:[{id:1,cityCode:"350000",cityName:"福建省"},
      {id:2,cityCode:"350100",cityName:"福州市"},{id:3,cityCode:"350200",cityName:"厦门市"},
      {id:4,cityCode:"350300",cityName:"莆田市"},{id:5,cityCode:"350400",cityName:"三明市"},
      {id:6,cityCode:"350500",cityName:"泉州市"},{id:7,cityCode:"350600",cityName:"漳州市"},
      {id:8,cityCode:"350700",cityName:"南平市"},{id:9,cityCode:"350800",cityName:"龙岩市"},
      {id:9,cityCode:"350900",cityName:"宁德市"}]},         
      {id:14,cityCode:"360000",cityName:"江西省",city:[{id:1,cityCode:"360000",cityName:"江西省"},
      {id:2,cityCode:"360100",cityName:"南昌市"},{id:3,cityCode:"360200",cityName:"景德镇"},
      {id:4,cityCode:"360300",cityName:"萍乡市"},{id:5,cityCode:"360400",cityName:"九江市"},
      {id:6,cityCode:"360500",cityName:"新余市"},{id:7,cityCode:"360600",cityName:"鹰潭市"},
      {id:8,cityCode:"360700",cityName:"赣州市"},{id:9,cityCode:"360800",cityName:"吉安市"},
      {id:10,cityCode:"360900",cityName:"宜春市"},{id:11,cityCode:"361000",cityName:"抚州市"},
      {id:12,cityCode:"361100",cityName:"上饶市"}]},
      {id:15,cityCode:"370000",cityName:"山东省",city:[{id:1,cityCode:"370000",cityName:"山东省"},        
      {id:2,cityCode:"370100",cityName:"济南市"},{id:3,cityCode:"370200",cityName:"青岛市"},
      {id:4,cityCode:"370300",cityName:"淄博市"},{id:5,cityCode:"370400",cityName:"枣庄市"},
      {id:6,cityCode:"370500",cityName:"东营市"},{id:7,cityCode:"370600",cityName:"烟台市"},
      {id:8,cityCode:"370700",cityName:"潍坊市"},{id:9,cityCode:"370800",cityName:"济宁市"},
      {id:10,cityCode:"379000",cityName:"泰安市"},{id:11,cityCode:"371000",cityName:"威海市"},
      {id:12,cityCode:"371100",cityName:"日照市"},{id:13,cityCode:"371300",cityName:"临沂市"},
      {id:14,cityCode:"371400",cityName:"德州市"},{id:15,cityCode:"371500",cityName:"聊城市"},
      {id:16,cityCode:"371600",cityName:"滨州市"},{id:17,cityCode:"371700",cityName:"菏泽市"}]}, 
      {id:16,cityCode:"410000",cityName:"河南省",city:[{id:1,cityCode:"410000",cityName:"河南省"},
      {id:2,cityCode:"410100",cityName:"郑州市"},{id:3,cityCode:"410200",cityName:"开封市"},
      {id:4,cityCode:"410300",cityName:"洛阳市"},{id:5,cityCode:"410400",cityName:"平顶山市"},
      {id:6,cityCode:"410500",cityName:"安阳市"},{id:7,cityCode:"410600",cityName:"鹤壁市"},
      {id:8,cityCode:"410700",cityName:"新乡市"},{id:9,cityCode:"410800",cityName:"焦作市"},
      {id:10,cityCode:"410900",cityName:"濮阳市"},{id:11,cityCode:"411000",cityName:"许昌市"},
      {id:12,cityCode:"411100",cityName:"漯河市"},{id:13,cityCode:"412000",cityName:"三门峡市"},
      {id:14,cityCode:"411300",cityName:"南阳市"},{id:15,cityCode:"411400",cityName:"商丘市"},
      {id:16,cityCode:"411500",cityName:"信阳市"},{id:17,cityCode:"416000",cityName:"周口市"},
      {id:18,cityCode:"411700",cityName:"驻马店市"},{id:19,cityCode:"41900100",cityName:"济源市"}]},
      {id:17,cityCode:"420000",cityName:"湖北省",city:[{id:1,cityCode:"420000",cityName:"湖北省"},
      {id:2,cityCode:"420100",cityName:"武汉市"},{id:3,cityCode:"420200",cityName:"黄石市"},
      {id:4,cityCode:"420300",cityName:"十堰市"},{id:5,cityCode:"420500",cityName:"宜昌市"},
      {id:6,cityCode:"420600",cityName:"襄阳市"},{id:7,cityCode:"420700",cityName:"鄂州市"},
      {id:8,cityCode:"420800",cityName:"荆门市"},{id:9,cityCode:"420900",cityName:"孝感市"},
      {id:10,cityCode:"421000",cityName:"荆州市"},{id:11,cityCode:"421100",cityName:"黄冈市"},
      {id:12,cityCode:"421200",cityName:"咸宁市"},{id:13,cityCode:"421300",cityName:"随州市"},
      {id:14,cityCode:"422800",cityName:"恩施州"},{id:15,cityCode:"42900400",cityName:"仙桃市"},
      {id:16,cityCode:"42900500",cityName:"潜江市"},{id:17,cityCode:"42900600",cityName:"天门市"},
      {id:18,cityCode:"42902100",cityName:"神农架林区"}]},
      {id:18,cityCode:"430000",cityName:"湖南省",city:[{id:1,cityCode:"430000",cityName:"湖南省"},
      {id:2,cityCode:"430100",cityName:"长沙市"},{id:3,cityCode:"430200",cityName:"株洲市"},
      {id:4,cityCode:"430300",cityName:"湘潭市"},{id:5,cityCode:"430400",cityName:"衡阳市"},
      {id:6,cityCode:"430500",cityName:"邵阳市"},{id:7,cityCode:"430600",cityName:"岳阳市"},
      {id:8,cityCode:"430700",cityName:"常德市"},{id:9,cityCode:"430800",cityName:"张家界市"},
      {id:10,cityCode:"439000",cityName:"益阳市"},{id:11,cityCode:"431000",cityName:"郴州市"},
      {id:12,cityCode:"431100",cityName:"永州市"},{id:13,cityCode:"431200",cityName:"怀化市"},
      {id:14,cityCode:"431300",cityName:"娄底市"},{id:15,cityCode:"433100",cityName:"湘西州"}]},       
      {id:19,cityCode:"440000",cityName:"广东省",city:[{id:1,cityCode:"440000",cityName:"广东省"},
      {id:2,cityCode:"440100",cityName:"广州市"},{id:3,cityCode:"440200",cityName:"韶关市"},
      {id:4,cityCode:"440300",cityName:"深圳市"},{id:5,cityCode:"440400",cityName:"珠海市"},
      {id:6,cityCode:"440500",cityName:"汕头市"},{id:7,cityCode:"440600",cityName:"佛山市"},
      {id:8,cityCode:"440700",cityName:"江门市"},{id:9,cityCode:"440800",cityName:"湛江市"},
      {id:10,cityCode:"440900",cityName:"茂名市"},{id:11,cityCode:"441200",cityName:"肇庆市"},
      {id:12,cityCode:"441300",cityName:"惠州市"},{id:13,cityCode:"441400",cityName:"梅州市"},
      {id:14,cityCode:"441500",cityName:"汕尾市"},{id:15,cityCode:"441600",cityName:"河源市"},
      {id:16,cityCode:"441700",cityName:"阳江市"},{id:17,cityCode:"441800",cityName:"清远市"},
      {id:18,cityCode:"441900",cityName:"东莞市"},{id:19,cityCode:"442000",cityName:"中山市"},
      {id:20,cityCode:"445100",cityName:"潮州市"},{id:21 ,cityCode:"445200",cityName:"揭阳市"},
      {id:20,cityCode:"445300",cityName:"云浮市"}]},        
      {id:20,cityCode:"450000",cityName:"广西壮族自治区",city:[{id:1,cityCode:"450000",cityName:"广西壮族自治区"},
      {id:2,cityCode:"450100",cityName:"南宁市"},{id:3,cityCode:"450200",cityName:"柳州市"},
      {id:4,cityCode:"450300",cityName:"桂林市"},{id:5,cityCode:"450400",cityName:"梧州市"},
      {id:6,cityCode:"450500",cityName:"北海市"},{id:7,cityCode:"450600",cityName:"防城港市"},
      {id:8,cityCode:"450700",cityName:"钦州市"},{id:9,cityCode:"450800",cityName:"贵港市"},
      {id:10,cityCode:"450900",cityName:"玉林市"},{id:11,cityCode:"451000",cityName:"百色市"},
      {id:12,cityCode:"451100",cityName:"贺州市"},{id:13,cityCode:"451200",cityName:"河池市"},
      {id:14,cityCode:"451300",cityName:"来宾市"},{id:15,cityCode:"451400",cityName:"崇左市"}]},         
      {id:21,cityCode:"460000",cityName:"海南省",city:[{id:1,cityCode:"460000",cityName:"海南省"},
      {id:2,cityCode:"460100",cityName:"海口市"},{id:3,cityCode:"460200",cityName:"三亚市"},
      {id:4,cityCode:"460300",cityName:"三沙市"},{id:5,cityCode:"460400",cityName:"儋州市"},
      {id:6,cityCode:"469000",cityName:"省直辖县级行政区划"}]},
      {id:22,cityCode:"500000",cityName:"重庆市",city:[{id:1,cityCode:"500000",cityName:"重庆市"},
      {id:2,cityCode:"500101",cityName:"万州区"},{id:3,cityCode:"500102",cityName:"涪陵区"},
      {id:4,cityCode:"500103",cityName:"渝中区"},{id:5,cityCode:"500104",cityName:"大渡口区"},
      {id:6,cityCode:"500105",cityName:"江北区"},{id:7,cityCode:"500106",cityName:"沙坪坝区"},
      {id:8,cityCode:"500107",cityName:"九龙坡区"},{id:9,cityCode:"500108",cityName:"南岸区"},
      {id:10,cityCode:"500109",cityName:"北碚区"},{id:11,cityCode:"500110",cityName:"綦江区"},
      {id:12,cityCode:"500111",cityName:"大足区"},{id:13,cityCode:"500112",cityName:"渝北区"},
      {id:14,cityCode:"500113",cityName:"巴南区"},{id:15,cityCode:"500114",cityName:"黔江区"},
      {id:16,cityCode:"500115",cityName:"长寿区"},{id:17,cityCode:"500116",cityName:"江津区"},
      {id:18,cityCode:"500117",cityName:"合川区"},{id:19,cityCode:"500118",cityName:"永川区"},
      {id:20,cityCode:"500119",cityName:"南川区"},{id:21,cityCode:"500120",cityName:"璧山区"},
      {id:22,cityCode:"500151",cityName:"铜梁区"},{id:23,cityCode:"500152",cityName:"潼南区"},
      {id:24,cityCode:"500153",cityName:"荣昌区"},{id:25,cityCode:"500154",cityName:"开州区"},
      {id:26,cityCode:"500155",cityName:"梁平区"},{id:27,cityCode:"500156",cityName:"武隆区"},
      {id:28,cityCode:"500229",cityName:"城口县"},{id:29,cityCode:"500230",cityName:"丰都县"},
      {id:30,cityCode:"500231",cityName:"垫江县"},{id:31,cityCode:"500233",cityName:"忠县"},
      {id:32,cityCode:"500235",cityName:"云阳县"},{id:33,cityCode:"500236",cityName:"奉节县"},
      {id:34,cityCode:"500237",cityName:"巫山县"},{id:35,cityCode:"500238",cityName:"巫溪县"},
      {id:36,cityCode:"500240",cityName:"石柱县"},{id:37,cityCode:"500241",cityName:"秀山县"},
      {id:38,cityCode:"500242",cityName:"酉阳县"},{id:39,cityCode:"500243",cityName:"彭水县"}]},         
      {id:23,cityCode:"510000",cityName:"四川省",city:[{id:1,cityCode:"510000",cityName:"四川省"},
      {id:2,cityCode:"510100",cityName:"成都市"},{id:3,cityCode:"510300",cityName:"自贡市"},
      {id:4,cityCode:"510400",cityName:"攀枝花市"},{id:5,cityCode:"510500",cityName:"泸州市"},
      {id:6,cityCode:"510600",cityName:"德阳市"},{id:7,cityCode:"510700",cityName:"绵阳市"},
      {id:8,cityCode:"510800",cityName:"广元市"},{id:9,cityCode:"510900",cityName:"遂宁市"},
      {id:10,cityCode:"511000",cityName:"内江市"},{id:11,cityCode:"511100",cityName:"乐山市"},
      {id:12,cityCode:"511300",cityName:"南充市"},{id:13,cityCode:"511400",cityName:"眉山市"},
      {id:14,cityCode:"511500",cityName:"宜宾市"},{id:15,cityCode:"511600",cityName:"广安市"},
      {id:16,cityCode:"511700",cityName:"达州市"},{id:17,cityCode:"511800",cityName:"雅安市"},
      {id:18,cityCode:"511900",cityName:"巴中市"},{id:19,cityCode:"512000",cityName:"资阳市"},
      {id:20,cityCode:"513200",cityName:"阿坝州"},{id:21,cityCode:"513300",cityName:"甘孜州"},
      {id:22,cityCode:"513400",cityName:"凉山州"}]},
      {id:24,cityCode:"520000",cityName:"贵州省",city:[{id:1,cityCode:"520000",cityName:"贵州省"},
      {id:2,cityCode:"520100",cityName:"贵阳市"},{id:3,cityCode:"520200",cityName:"六盘水市"},
      {id:4,cityCode:"520300",cityName:"遵义市"},{id:5,cityCode:"520400",cityName:"安顺市"},
      {id:6,cityCode:"520500",cityName:"毕节市"},{id:7,cityCode:"520600",cityName:"铜仁市"},
      {id:8,cityCode:"522300",cityName:"黔西南州"},{id:9,cityCode:"522600",cityName:"黔东南州"},
      {id:10,cityCode:"522700",cityName:"黔南州"}]},
      {id:25,cityCode:"530000",cityName:"云南省",city:[{id:1,cityCode:"530000",cityName:"云南省"},
      {id:2,cityCode:"530100",cityName:"昆明市"},
      {id:3,cityCode:"530300",cityName:"曲靖市"},{id:4,cityCode:"530400",cityName:"玉溪市"},
      {id:5,cityCode:"530500",cityName:"保山市"},{id:6,cityCode:"530600",cityName:"昭通市"},
      {id:7,cityCode:"530700",cityName:"丽江市"},{id:8,cityCode:"530800",cityName:"普洱市"},
      {id:9,cityCode:"530900",cityName:"临沧市"},{id:10,cityCode:"532300",cityName:"楚雄州"},
      {id:11,cityCode:"532500",cityName:"红河州"},{id:12,cityCode:"532600",cityName:"文山州"},
      {id:13,cityCode:"532800",cityName:"西双版纳州"},{id:14,cityCode:"532900",cityName:"大理州"},
      {id:15,cityCode:"533100",cityName:"德宏州"},{id:16,cityCode:"533300",cityName:"怒江州"},
      {id:17,cityCode:"533400",cityName:"迪庆州"}]},
      {id:25,cityCode:"540000",cityName:"西藏自治区",city:[{id:1,cityCode:"540000",cityName:"西藏自治区"},
      {id:2,cityCode:"540100",cityName:"拉萨市"},{id:3,cityCode:"540200",cityName:"日喀则市"},
      {id:4,cityCode:"540300",cityName:"昌都市"},{id:5,cityCode:"540400",cityName:"林芝市"},
      {id:6,cityCode:"540500",cityName:"山南市"},{id:7,cityCode:"540600",cityName:"那曲市"},
      {id:8,cityCode:"54250000",cityName:"阿里地区"}]},
      {id:26,cityCode:"610000",cityName:"陕西省",city:[{id:1,cityCode:"610000",cityName:"陕西省"},
      {id:2,cityCode:"610100",cityName:"西安市"},{id:3,cityCode:"610200",cityName:"铜川市"},
      {id:4,cityCode:"610300",cityName:"宝鸡市"},{id:5,cityCode:"610400",cityName:"咸阳市"},
      {id:6,cityCode:"610500",cityName:"渭南市"},{id:7,cityCode:"610600",cityName:"延安市"},
      {id:8,cityCode:"610700",cityName:"汉中市"},{id:9,cityCode:"610800",cityName:"榆林市"},
      {id:10,cityCode:"610900",cityName:"安康市"},{id:11,cityCode:"611000",cityName:"商洛市"}]},
      {id:27,cityCode:"620000",cityName:"甘肃省",city:[{id:1,cityCode:"620000",cityName:"甘肃省"},
      {id:2,cityCode:"620100",cityName:"兰州市"},{id:3,cityCode:"620200",cityName:"嘉峪关市"},
      {id:4,cityCode:"620300",cityName:"金昌市"},{id:5,cityCode:"620400",cityName:"白银市"},
      {id:6,cityCode:"620500",cityName:"天水市"},{id:7,cityCode:"620600",cityName:"武威市"},
      {id:8,cityCode:"620700",cityName:"张掖市"},{id:9,cityCode:"620800",cityName:"平凉市"},
      {id:10,cityCode:"620900",cityName:"酒泉市"},{id:11,cityCode:"621000",cityName:"庆阳市"},
      {id:12,cityCode:"621100",cityName:"定西市"},{id:13,cityCode:"621200",cityName:"陇南市"},
      {id:14,cityCode:"622900",cityName:"临夏州"},{id:15,cityCode:"623000",cityName:"甘南州"}]},
      {id:28,cityCode:"630000",cityName:"青海省",city:[{id:1,cityCode:"630000",cityName:"青海省"},
      {id:2,cityCode:"630100",cityName:"西宁市"},{id:3,cityCode:"630200",cityName:"海东市"},
      {id:4,cityCode:"630300",cityName:"黄南州"},{id:5,cityCode:"630500",cityName:"海南州"},
      {id:6,cityCode:"630600",cityName:"果洛州"},{id:7,cityCode:"630700",cityName:"青海省"},
      {id:8,cityCode:"630800",cityName:"青海省"},{id:8,cityCode:"632200",cityName:"海北州"}]},
      {id:29,cityCode:"640000",cityName:"宁夏回族自治区",city:[{id:1,cityCode:"640000",cityName:""},
      {id:2,cityCode:"640100",cityName:"银川市"},{id:3,cityCode:"640200",cityName:"石嘴山市"},
      {id:4,cityCode:"640300",cityName:"吴忠市"},{id:5,cityCode:"640400",cityName:"固原市"},
      {id:6,cityCode:"640500",cityName:"中卫市"}]},
      {id:30,cityCode:"650000",cityName:"新疆维吾尔自治区",city:[{id:1,cityCode:"650000",cityName:"新疆维吾尔自治区"},
      {id:2,cityCode:"650100",cityName:"乌鲁木齐市"},{id:3,cityCode:"650200",cityName:"克拉玛依市"},
      {id:4,cityCode:"650400",cityName:"吐鲁番市"},{id:5,cityCode:"650500",cityName:"哈密市"},
      {id:6,cityCode:"652300",cityName:"昌吉州"},{id:7,cityCode:"652700",cityName:"博州"},
      {id:8,cityCode:"652800",cityName:"巴州"},{id:9,cityCode:"652900",cityName:"阿克苏地区"},
      {id:10,cityCode:"653000",cityName:"克州"},{id:11,cityCode:"653100",cityName:"喀什地区"},
      {id:12,cityCode:"653200",cityName:"和田地区"},{id:13,cityCode:"654000",cityName:"伊犁州"},
      {id:14,cityCode:"654200",cityName:"塔城地区"},{id:15,cityCode:"654300",cityName:"阿勒泰地区"},
      {id:16,cityCode:"659000",cityName:"直辖县级政区"}]},
      {id:31,cityCode:"710000",cityName:"台湾省",city:[{id:1,cityCode:"710000",cityName:"台湾省"}]},
      {id:32,cityCode:"810000",cityName:"香港特别行政区",city:[{id:1,cityCode:"810000",cityName:"香港特别行政区"}]},
      {id:33,cityCode:"820000",cityName:"澳门特别行政区",city:[{id:1,cityCode:"820000",cityName:"澳门特别行政区"}]}]
          
      }
    },
    created() {
        // this.getLoginStatus()
        console.log(this.userInfo,1567);
        bus.$emit('info',this.userInfo)
        if(this.loginStatus==true){
          if(this.userInfo && this.userInfo.username!=''){
            this.getUserBuyNum()
          }
        }
    },

    computed:{
        ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo,
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
    },
    methods: {
      getUserBuyNum(){
        let data  = {
          username : this.userInfo.username
        }
        getUserBuyNum(data).then((res)=>{
          if(res.status==200){
            console.log(res);
            if(res.data.data[0].buyed==null){
              this.zero = false
            }else{
              console.log(res.data.data[0].buyed);
              if(res.data.data[0].buyed!=''){
                let num = res.data.data[0].buyed.split(',').length - 1
                // alert(num)
                this.buyedNum = num
                this.zero = true
              }
            }
          }
        })
        
      },
      morefun(){
        this.$router.push({
          path:"/moreFun"
        })
      },
      mineInfo(){
      //   // alert(5)
      //   // axios({
      //   //   url:'https://ut57.xyz/api/web/info/detail.json?infoId=339562'
      //   // }).then((res)=>{
      //   //   console.log(res,7777);
      //   // })
      if(this.loginStatus == false){
         Toast.fail('请先登录！')
      }else{
        this.$router.push({
           path:"/agent"
        })
      }
      },
      ...mapMutations(['loginOut']),
      // 登录
      login(){
        this.$router.push({
          path:"/login"
        })
      },
      Tc(){
        let data = {
          loginState : 0,
          username : this.userInfo.username
        }
        uploginState(data).then((res)=>{
          if(res){
            console.log(res);
          }
        })
      },
      // 注册
      register(){
        

          this.$router.push({
              path:"/register"
          })
        },
      // 详情页
      goDetail(val){
          console.log(val);
          console.log(val,88);
          this.$router.push({
          path: "/detail_GM",
          query: { id: val.id },
        });
      },
        //处理破图
      moveErrorImg(event) {
          event.currentTarget.src = require("../../assets/images/err.jpg");
          event.currentTarget.style.width = "auto";
          return true;
        },
        // 积分充值
        jfczbtn(){
            if(this.loginStatus == false){
              Toast.fail('请先登录！')
              setTimeout(()=>{
              this.$router.push({
                  path:"./login"
                }).catch( ()=> {})
              },1000) 
            }else{
              let data = {
              kami:this.kamiJF
            }
            if(!this.kamiJF){
              // Toast.fail('请输入充值卡密')
              Dialog.alert({
                title: '温馨提示',
                message: '请输入充值卡密',
              }).then(() => {
                // on close
              });
            }else{
                getKami(data).then((res)=>{
                  if(res.status==200){
                    console.log(res.data.data,1566666);
                    
                    if(res.data.data.length==0){
                      // Toast.fail('请输入正确的卡密')
                      Dialog.alert({
                        title: '温馨提示',
                        message: '请输入正确的卡密',
                      }).then(() => {
                        // on close
                      });
                    }else if(res.data.data[0].isused==1){
                      // Toast.fail('该卡密已被使用')
                      Dialog.alert({
                        title: '温馨提示',
                        message: '该卡密已被使用',
                      }).then(() => {
                        // on close
                      });
                    }else{
                      // this.usertype = res.data.data[0].type
                       this.kmType = res.data.data[0].type
                        // let data = {
                        //   username:this.userInfo.username,
                        //   usertype:this.usertype,
                        //   kami:this.kami
                        // }
                        // 获取用户积分
                        let data = {
                          username:this.userInfo.username,
                        }
                        getJFXX(data).then((res)=>{
                          if(res.status == 200 ){
                            // console.log(res.data.data[0].score,"积分");
                            let score = res.data.data[0].score
                            
                            let jfscore 
                            if(this.kmType == 6){
                                jfscore = 158
                            }else if(this.kmType == 7){
                                jfscore = 446
                            }else if(this.kmType == 8){
                                jfscore == 776
                            }
                            
                            let data = {
                              username:this.userInfo.username,
                              score: score*1 + jfscore*1
                            }
                            payTZ(data).then((res)=>{
                              if(res.status == 200){
                                // Toast.success('充值成功!');
                                Dialog.alert({
                                  title: '温馨提示',
                                  message: '充值成功,如遇积分未到账,请退出重新登陆',
                                }).then(() => {
                                  // on close
                                });
                                this.$store.commit('updataJF',score*1 + jfscore*1)
                                this.jfczShow = false
                                
                                let data  = {
                                  kami : this.kamiJF
                                }
                                upkmState(data).then((res)=>{
                                  if(res.status == 200){
                                    console.log(res);
                                    this.kamiJF = ""
                                  }
                                })
                              }
                            })
                          }
                        })


                     



                      // upUsertype(data).then((res)=>{
                      //   if(res.status == 200){
                      //     Toast.success('升级成功,重新登录即可');
                      //     this.sjhyShow = false
                      //     let data  = {
                      //       kami : this.kami
                      //     }
                      //     upkmState(data).then((res)=>{
                      //       if(res.status == 200){
                      //         console.log(res);
                      //       }
                      //     })
                      //     // this.$router.push({
                      //     //     path:"./login"
                      //     // }).catch( ()=> {})
                      //     setTimeout(()=>{
                      //       this.loginOut()
                      //       this.Tc()
                      //     },500)
                      //   }
                      // })
                    }
                  }
            })
            }
            }
        },
      
        // 会员升级
        hysj(){
          // alert(this.loginStatus)
          // alert(this.kami)
          if(this.loginStatus == false){
            // alert("登录")
            Toast.fail('请先登录！')
            setTimeout(()=>{
              this.$router.push({
                  path:"./login"
                }).catch( ()=> {})
            },1000)    
          }else{
            console.log(1);
            let data = {
              kami:this.kami
            }
            if(!this.kami){
              // Toast.fail('请输入激活卡密')
              Dialog.alert({
                  title: '温馨提示',
                  message: '请输入激活卡密',
                }).then(() => {
                  // on close
                });
            }else{
              getKami(data).then((res)=>{
              if(res.status==200){
                console.log(res.data.data);
                
                if(res.data.data.length==0){
                  //  Toast.fail('请输入正确的卡密')
                  Dialog.alert({
                    title: '温馨提示',
                    message: '请输入正确的卡密',
                  }).then(() => {
                    // on close
                  });
                }else if(res.data.data[0].isused==1){
                  //  Toast.fail('该卡密已被使用')
                  Dialog.alert({
                    title: '温馨提示',
                    message: '该卡密已被使用',
                  }).then(() => {
                    // on close
                  });
                }else{
                  this.usertype = res.data.data[0].type
                    let data = {
                      username:this.userInfo.username,
                      usertype:this.usertype,
                      kami:this.kami
                    }
                  upUsertype(data).then((res)=>{
                    if(res.status == 200){
                      // Toast.success('升级成功,重新登录即可');
                      Dialog.alert({
                        title: '温馨提示',
                        message: '升级成功,如遇会员未到账,请重新登陆',
                      }).then(() => {
                        // on close
                      });
                      this.sjhyShow = false
                      
                      let data  = {
                        kami : this.kami
                      }
                      upkmState(data).then((res)=>{
                        if(res.status == 200){
                          console.log(res);
                          this.kami = ""
                        }
                      })
                      // this.$router.push({
                      //     path:"./login"
                      // }).catch( ()=> {})
                      setTimeout(()=>{
                        // this.Tc()
                        this.loginOut()
                      },500)
                    }
                  })
                }
              }
            })
            }
            
          }
        },
        // 体验积分
        tyjf(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/7063E43F"
        },
        // 大众积分
        dzjf(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/A1EFD3FC"
        },
        // 超值积分
        czjf(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/BCF70B21"
        },
        // 季度会员
        jdhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/381D9D1F"
        },
        // 年度会员
        ndhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/F0F163FE"
        },
        // 永久会员
        yjhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.yixinka.com//details/79839176"
        },
        // /----------------------------------------------------------------/ 
          // 季度会员
        jdhy_vx(){
          window.location.href="https://vip.zaanz.cn/details/3E669152"
        },
        // 年度会员
        ndhy_vx(){
          window.location.href="https://vip.zaanz.cn/details/A2DBFE26"
        },
        // 永久会员
        yjhy_vx(){
          window.location.href="https://vip.zaanz.cn/details/75D10F82"
        },
        // 体验积分
        tyjf_vx(){
          window.location.href="https://vip.zaanz.cn/details/4789356D"
        },
        // 大众积分
        dzjf_vx(){
          window.location.href="https://vip.zaanz.cn/details/83995842"
        },
        // 超值积分
        czjf_vx(){
          window.location.href="https://vip.zaanz.cn/details/16426E11"
        },
        orderQuery(){
          window.location.href="https://www.yixinka.com/orderquery"
        },
        // 升级会员弹窗
        sjhy(){
          this.sjhyShow = true
        },
         // 积分充值
        jfcz(){
          this.jfczShow = true
        },
        //积分规则
        jfgz(){
          this.jfgzShow = true
        },
        //鉴别指南
        jbzn(){
          this.jbznShow = true
        },
        // 兑换活动
        replace(){
           this.$router.push({
            path:'/Replace'
          })
        },
        gohome(){
          this.gohomeShow = true
        },
        // 我的推广
        mineTg(){
          if(this.loginStatus == false){
            // alert("登录")
            Toast.fail('请先登录！')
          }
          else{
            this.$router.push({
              path:'/ads'
            })
          }
        },
        // 我的解锁
        mineTZ(){
          if(this.loginStatus == false){
            // alert("登录")
            Toast.fail('请先登录！')
          }else{
              this.mineTZShow = true
              let name = {
                username:this.userInfo.username
              }
              getTZ(name).then((res)=>{
                if(res.status == 200){
                  console.log(res.data.data[0].buyed,12333333);
                  if(res.data.data[0].buyed == "" || res.data.data[0].buyed == null){
                    // alert(66)
                    Toast.fail('暂无购买信息')
                  }else{
                       let arr = res.data.data[0].buyed.slice(0,-1)
                    // arr.replace(reg,"")
                    let data = {
                      ids:arr
                    }
                    console.log(arr);
                    getTzInfo(data).then((res)=>{
                      if(res.status == 200){
                        console.log(res.data.data,"125");
                        this.dataList = res.data.data
                      }
                    })
                  }
                 
                }
              })
              console.log(this.userInfo,"15666666666");
         }

    
          
       
        },
        // 修改资料
        xgzl(){
          if(this.loginStatus == false){
            Toast.fail('请先登录！')
          }else{
            this.xgzlShow = true
            this.username = this.userInfo.username
            this.adress = this.userInfo.email
          }
          // this.xgzlShow = true
          // this.username = this.userInfo
          // this.adress = this.userInfo.email
          // console.log(this.userInfo,156);
        },
        //问题反馈
        feedback() { 
          this.$router.push({
              path:'/Feedback'
            })
        },  
        resetState(){
          // // alert(1)
          // let data = {
          //   loginState : 1
          // }
          // resetState(data).then((res)=>{
          //   console.log(res);
          //   Toast.success('修改成功')
          // })
          this.$router.push({
            path:'/Super'
          })
        },
        record(){
          this.$router.push({
            path:'/Record'
          })
        },
        logs(){
          this.$router.push({
            path:'/logs'
          })
        },
        passBtn(){
          Toast.success('请保管好密码 暂不提供修改')
        },
        // 格式化时间
        filterTime(value) {
        if (value != null || value != "") {
            let val = value;
            let momentObj = moment(val * 1).format("YYYY-MM-DD");
            return momentObj;
        } else {
            return "";
        }
        },

         filterTimePIC(value) {
      if (value != null || value != "") {
        let val = value;
        let momentObj = moment(val * 1).format("YYMMDD");
        console.log(momentObj,"156666");
        return momentObj;
      } else {
        return "";
      }
    },
        // 格式化地区
        filterCity(val){
      
        let cityCode = val
      if(cityCode != null || cityCode !=""){
        let val = cityCode.toString()
        let twoCode = val.slice(0,2)
        let arr = this.province.filter((item)=>{
            return item.cityCode.toString().slice(0,2) == twoCode
        })
        let brr = arr[0].city.filter((item)=>{
            return item.cityCode == cityCode
        })
        let pName = arr[0].cityName
        let cName = brr[0].cityName
        // let pName
        // let cName
        // if(arr[0]!=null && arr[0]!=''){
        // pName = arr[0].cityName

        // }
        // if(brr[0]!=null && brr[0]!=''){
        // cName = brr[0].cityName

        // }
        // console.log(arr);
        // console.log(brr);
        // console.log(twoCode);
        // console.log(val);
        // console.log(pName+cName);
        return pName+'-'+cName
      }else{
        return '天上人间'
      }
        }
    },
   
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container {
  width: 100%;
  background-color: #f5f5f5;
  height: calc(100% - 40px);
  padding: 5px 10px;
  box-sizing: border-box;
}
#container .header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #ec4899;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 9999; */
}
#container .logo{
    position: absolute;
    /* top: 10px; */
    left: calc(50% - 30px);
    z-index: 9999;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
#container .notlogin{
    box-sizing: border-box;
    width: 100%;
    height: 140px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
#container .notlogin .text{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    text-align: center;
}
#container .notlogin .login_btn{
    height: 50px;
    text-align: left;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
#container .listInfo{
  width: 100%;
  /* height: 270px; */
  background-color: #fff;
  margin-top: 10px;
  height: auto;
}
#container .listInfo ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
#container .listInfo ul li{
  width: 25%;
  height: 90px;
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 12px;
}
#container .role{
  width: 100%;
  height: 90px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}
#container .role span{
  font-size: 14px;
}
#container .role li{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
#container .role i{
  font-size: 24px;
  color: #ec4899;
}
#container .success{
  width: 100%;
  height:120px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#container .success li{
  text-align: center;
}
.content_tip{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 5px 10px  0;
  box-sizing: border-box;
}
.content_tip .content{
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  padding: 10px 5px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.content_tip .content .font{
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}
table,table tr th,table tr td{
  border: 1px solid #ccc;
}
table{
  border-collapse: collapse;
  width: 100%;
}
.zfxx{
  font-size: 16px;
  padding-left: 20px;
  color: #ec4899;
  font-weight: 700;
}
.vipBtn{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  /* line-height: 60px; */
  margin-top: 5px;
}
.vipBtn li{
  background-color: #ec4899;
  width: 22%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.vipBtn li:nth-last-child(1){
  background-color: #1a73e8;
  border-radius: 20px;
}

.shengji{
  width: 100%;
  height: 50px;
}
.shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
.shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
/deep/.van-form{
    height: 100%;
}
/deep/.van-cell{
    height: 75px;
}
/deep/.van-cell span{
    font-size: 18px;
    font-weight: 700;
    color: #ec4899;
}
/deep/.van-field__label{
    text-align: right;
    margin-top: 20px;
}
/deep/.van-cell__value{
    margin-top: 20px;
}
.subBtn{
  width: 120px;
  line-height: 40px;
  height: 40px;
  color: #fff;
  font-size: 20px;
  background-color: #ec4899;
  text-align: center;
  margin-top: 50px;
  margin-left: calc(50% - 60px);
}





.content_tip .content .list {
  box-sizing: border-box;
  width: 100%;
  height: 176px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
}
.content_tip .content .list .left {
  width: 65%;
  height: 100%;
}
.content_tip .content .list .left2 {
  width: 100%;
  height: 100%;
}
.content_tip .content .list .right {
  width: 35%;
  height: 100%;
}
.content_tip .content .list .right img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.content_tip .content .list .left .tit {
  font-size: 16px;
  height: 20px;
  margin-left: -65px;
}
.content_tip .content .list .left .tip {
  height: 15px;
  /* font-size: 12px; */
}
.bottom .city {
  color: #ec4899;
}
.listInfo  ul li /deep/.van-icon .van-info{
  right: 16px;
  top:3px
}
</style>